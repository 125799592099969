export const PRODUCT_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment productActionButtonAddnoteFragment on Product {
    __typename
    id
    uid
    name
    images {
      fullFileResource {
        schemaCode
        path
      }
    }
  }
`;
